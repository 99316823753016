import style from "./../../styles/Sidebar.module.css";
import SideNavData from "./SideNavData";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "./../../Features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faTableList } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";

const Sidebar = ({ setIsSandwichOpen, isSandwichOpen, toggle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userName = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  const getdata = useApi(api.getAllComponent);
  const navigate = useNavigate();

  const [data, setdata] = useState(SideNavData);
  const [subNav, setSubNav] = useState([]);

  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    navigate("/login");
  };

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const data = await getdata.request();
        setSubNav(
          data?.data?.success?.data?.map((table) => {
            return {
              subnavigation: true,
              title: table.name,
              path: `/components/${table.associated_table}`,
              icon: <FontAwesomeIcon icon={faTableList} />,
            };
          }),
        );
      } catch (error) {}
    };
    fetchdata();
  }, []);
  useEffect(() => {
    setdata((prev) => {
      return prev.map((table) => {
        if (table.title === "Components") {
          return { ...table, subNav: subNav };
        } else {
          return table;
        }
      });
    });
  }, [subNav]);

  // return (
  //   <div className={`bg-white shadowMain ${style.sidebar} ${!isSandwichOpen ? "" : style.sandwichOpen}`}>
  //     <div className={`justify-content-between justify-content-lg-center py-3 px-2 ${style.infoDiv}`}>
  //       <div className={`${style.userInfo}`}>
  //         <div className="d-flex align-items-center">
  //           <div className={`me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>{userName1}</div>
  //           {/* <img width='52px' height='52px' className='rounded-circle' src={profile} alt="profile" /> */}
  //         </div>
  //         <div>
  //           <p className="fw-bold mb-0 text-capitalize">{user && user.user_full_name}</p>
  //           <span className="fw-light text-capitalize">{user && user.user_type}</span>
  //         </div>
  //       </div>
  //       <div role="button" className={`text-white ${style.closeCTA}`} onClick={() => toggle()}>
  //         Close <FontAwesomeIcon icon={faClose} />
  //       </div>
  //     </div>
  //     <div className={` d-flex flex-column justify-content-between ${isSandwichOpen ? "align-items-center" : "align-items-start"} ${style.sidebarItems}`}>
  //       <div className={`${style.scroll} w-100`}>
  //         <Menu setIsSandwichOpen={setIsSandwichOpen} navData={data} isSandwichOpen={isSandwichOpen} />
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div
      id="sidebar_intro"
      // ref={wrapperRef}
      className={`bg-white ${style.sidebar} ${!isSandwichOpen ? "" : style.sandwichOpen}`}
    >
      <div className={`${isSandwichOpen ? "justify-content-xl-center" : ""} py-3 px-2 ${style.infoDiv}`}>
        <div className={`${style.userInfo}`}>
          <div className={`${style.profile_pic}`}>
            <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle text-uppercase ${style.name}`} id="topbar_user_thumbnail">
              {userName}
            </div>
            {/* <div className={`me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>{userName1}</div> */}
          </div>

          {!isSandwichOpen && (
            <div>
              <p className="fw-bold mb-0 text-capitalize">{user && user.user_full_name}</p>
              <span className="fw-light text-capitalize">{user && user.user_type}</span>
            </div>
          )}
        </div>
        {/* {!isSandwichOpen && (
          <div
            role="button"
            className={`text-white ${isSandwichOpen ? "d-sm-block d-lg-none" : "d-none"}`}
            onClick={() => {
              if (window.innerWidth <= 820) {
                window.tidioChatApi?.show();
              }
              setIsSandwichOpen(true);
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </div>
        )} */}
      </div>
      <div className={`d-flex flex-column justify-content-between ${isSandwichOpen ? "align-items-center" : "align-items-start"} ${style.sidebarItems}`}>
        <div className={`${style.scroll} ${style.scrollheight} w-100`} id="sidebar_navigation_menu">
          <Menu
            setIsSandwichOpen={setIsSandwichOpen}
            navData={data}
            isSandwichOpen={isSandwichOpen}
            // userType={userType}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
