import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import style from "./ModelFilter.module.css";

function ModelFilter({
  title,
  addButton = {
    title: "Add",
    link: "",
    page: 1,
  },
  search = {
    value: "",
    setValue: () => {},
  },
  statusFilter = {
    value: {
      value: null,
      label: "All",
    },
    setValue: () => {},
  },
}) {
  const user = useSelector((store) => store.auth.user);
  return (
    <>
      <div className="d-flex justify-content-center mx-4 mb-2">
        <h2 className="text-secondary">{title}</h2>
      </div>
      <div className="d-flex justify-content-between align-items-center  mx-4">
        <input type="search" placeholder="Search" className="px-3 rounded" value={search.value} onChange={search.setValue} />
        {["data-analyst", "data-operator"].includes(user.user_type) && (
          <Link to={addButton.link} state={{ page: addButton.page }}>
            <button>{addButton.title}</button>
          </Link>
        )}
      </div>
      {statusFilter && (
        <div className={`d-flex justify-content-between align-items-center mx-4 ${style.statusFilterContainer}`}>
          <Select
            options={[
              {
                value: null,
                label: "All",
              },
              {
                value: 0,
                label: "Live",
              },
              {
                value: 1,
                label: "Deleted",
              },
              {
                value: -1,
                label: "Pending Review",
              },
              {
                value: -2,
                label: "In-process",
              },
            ]}
            onChange={(e) => {
              statusFilter.setValue(e);
            }}
            value={statusFilter.value}
          />
        </div>
      )}
    </>
  );
}

export default ModelFilter;
