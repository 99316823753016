import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_DATA,
  // baseURL: "http://192.168.3.82:5010",
});

const config = {
  headers: {
    Authorization: localStorage.getItem("refreshToken"),
  },
};

//Component---------- START -------------

export function deleteComponent(table, id, password) {
  return api.delete(`/component/${table}/${id}`, {
    withCredentials: true,
    data: { password: password },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function unarchiveComponent(table, id, password) {
  return api.post(
    `/component/${table}/${id}`,
    { password },
    {
      withCredentials: true,
    },
  );
}

// Component---------- END---------------
