import React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar } from "react-bootstrap";
import logo from "./../assets/images/logo2.png";
import logo2 from "./../assets/images/logo3.png";
import style from "./../styles/Navbar.module.css";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import profile from "../assets/images/profile.png";
import signOut from "../assets/svg/signout.svg";
import { authActions } from "../Features/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import style1 from "../styles/Sidebar.module.css";

const Topbar = ({ toggle, isSandwichOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userName = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    navigate("/login");
  };

  // return (
  //   <Navbar
  //     className="shadowMain position-fixed w-100 mb-5"
  //     bg="white"
  //     style={{ zIndex: "22", height: "70px" }}
  //   >
  //     <div className="px-4 d-flex justify-content-between align-items-center w-100">
  //       <div
  //         className={`py-2 px-4 d-flex justify-content-center align-items-center gap-5 ${style.navItem}`}
  //       >
  //         <Link to="/">
  //           <div>
  //             <img src={logo2} className="me-2" alt="logo" />

  //             {!isSandwichOpen && (
  //               <img src={logo} className="me-4" alt="logo" />
  //             )}
  //           </div>
  //         </Link>
  //         <div style={{ width: "25px" }} className={style.sandwich}>
  //           <FontAwesomeIcon
  //             role="button"
  //             icon={faBars}
  //             onClick={() => toggle()}
  //           />
  //         </div>
  //       </div>
  //       <Dropdown
  //         className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`}
  //       >
  //         <Dropdown.Toggle
  //           variant="light"
  //           className={`border-0 p-0 text-secondary `}
  //           id="dropdown-basic"
  //           style={{ background: "transparent" }}
  //         >
  //           <div
  //             className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}
  //           >
  //             {/* <img
  //                     width="36px"
  //                     height="36px"
  //                     className="rounded-circle"
  //                     src={profile}
  //                     alt="profile"
  //                   /> */}
  //             {userName}
  //           </div>
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu
  //           className="rounded-0 border-0 shadow"
  //           style={{ left: "-6rem" }}
  //         >
  //           <Dropdown.Item
  //             className="purple"
  //             href=""
  //             eventKey="true"
  //             onClick={logout}
  //           >
  //             <span className={`${style.signOut}`}>
  //               <img src={signOut} alt="signout" /> {"Sign Out"}
  //             </span>
  //           </Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </div>
  //   </Navbar>
  // );

  return (
    <>
      <Navbar className="shadowMain position-fixed w-100" bg="white" style={{ zIndex: "22", height: "70px" }} id="topbar_tour">
        <div className="d-flex w-100" style={{ height: "67px" }}>
          <div className={`${style.clickableIcon}  ${style.hamburgerWithLogo}  justify-content-center`} onClick={toggle}>
            <FontAwesomeIcon className="bars" role="button" icon={faBars} />
          </div>
          <div className={`${!isSandwichOpen ? style.navItem : style.navItemClose} ${style.logoBorder}`}>
            <div className={`${!isSandwichOpen ? style.logo : style.logoClose} `}>
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={logo2}
                className="me-2"
                alt="logo"
              />
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={logo}
                className={`${!isSandwichOpen ? style.logofull : style.logofullHide} d-xs-none`}
                alt="logo"
              />
            </div>
          </div>

          <div className={`${!isSandwichOpen ? style.userInfoPart : style.userInfoPartClose} d-flex align-items-center`}>
            <div className={`${style.clickableIcon} ${style.hamburgerWithoutLogo} justify-content-center`} onClick={() => toggle()}>
              <FontAwesomeIcon className="bars" role="button" icon={faBars} id="topbar_hamburger" />
            </div>

            <div className={`${style.nextToSandwich} d-flex align-items-center justify-content-end`}>
              <div className={`${style.clickableIcon} d-flex justify-content-center align-items-center`}>
                <Dropdown className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`}>
                  <Dropdown.Toggle variant="light" className={`border-0 p-0 text-secondary `} id="dropdown-basic" style={{ background: "transparent" }}>
                    <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`} id="topbar_user_thumbnail">
                      {userName}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className=" border-0 shadow mt-1 p-3" style={{ left: "-7rem", borderRadius: "7px !important" }}>
                    <Dropdown.Item onClick={logout} className="purple" href="" eventKey="true">
                      <span className={` ms-1 ${style1.signOut} `}>
                        <img src={signOut} alt="signout" /> {"Sign Out"}
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Topbar;
