import { useEffect, useState } from "react";
import Dashboard from "../Components/Dashboard/Dashboard";
const Home = () => {
  return (
    <>
      <Dashboard />
    </>
  );
};

export default Home;
