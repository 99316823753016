import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PaginationBar from "../PaginationBar/PaginationBar";
import TableView from "../TableView/TableView";

////
import useApi from "../../Hooks/useApi";
import * as api from "../../api/getapi";
import * as deleteapi from "../../api/deleteapi";
import CustomizeTableview from "../TableView/CustomizeTableview";
import PopUpModal from "../PopUpModal";
import AddComponent from "./AddComponent";
import Select from "react-select";
import broken from "../../assets/images/broken.webp";

//

const DELAY_TIME = 100;

const Component = ({ componentData }) => {
  const componentDataApi = useApi(api.getComponentData);
  const noOfPagesApi = useApi(api.getComponentNoOfPages);
  const componentByIdApi = useApi(api.getComponentById);
  const deleteComponentApi = useApi(deleteapi.deleteComponent, { hasCatchError: true });
  const unarchiveComponentApi = useApi(deleteapi.unarchiveComponent, { hasCatchError: true });

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [editData, setEditData] = useState([]);

  const [dataRefresh, toggle] = useState(false);

  const [noRecord, setNoRecord] = useState(false);

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const [isCategory, setIsCategory] = useState({
    value: null,
    label: "Select Categories Filter",
  });

  const [showAddComponentModal, setShowAddComponentModal] = useState(false);
  const [showEditComponentModal, setShowEditComponentModal] = useState(false);

  useEffect(() => {
    async function getComponent() {
      try {
        const res = await componentDataApi.request(componentData.associated_table, isCategory?.value ? (componentData.is_filters === 1 ? categoryObject(isCategory) : null) : null, false, currentPage, sizeOfPages, search);
        if (res) {
          if (res.data.success.data.length === 0) {
            setNoRecord(true);
            setData(res.data.success.data);
          } else {
            setNoRecord(false);
            let data = res.data.success.data;
            if (componentData.name === "Brand") {
              data = data.map((item) => {
                item["logo"] = item["logo"] ? `${process.env.REACT_APP_URL_S3}/${item["logo"]}` : broken;
                return item;
              });
            }
            setData(data);
          }
        }
        const resNoOfPage = await noOfPagesApi.request(componentData.associated_table, sizeOfPages, search, isCategory?.value ? categoryObject(isCategory) : null);
        if (resNoOfPage) {
          setNoOfPages(resNoOfPage?.data?.success?.data?.no_of_pages);
        }
      } catch (error) {
        // setIsPageFound(false)
      }
    }
    getComponent();
  }, [componentData, dataRefresh, currentPage, noOfPages, sizeOfPages, search, isCategory]);

  useEffect(() => {
    setIsCategory({ value: null, label: "Select Categories Filter" });
    setSearch("");
    setCurrentPage(1);
    setSizeOfPages(10);
  }, [JSON.stringify(componentData)]);

  useEffect(() => {
    return () => {
      if (currentSearch.current !== search) {
        setCurrentPage(1);
      }
    };
  }, [search]);

  const onClickAddModal = () => {
    setShowAddComponentModal(true);
  };

  const edit = async (obj) => {
    try {
      // const res = await componentByIdApi.request(componentData.associated_table,obj['id']);
      // if(res){
      //     setEditData(res.data?.success?.data)
      //     setShowEditComponentModal(true)
      // }
      setEditData(obj);
      setShowEditComponentModal(true);
    } catch (error) {}
  };

  const archive = async (obj, password, callBack) => {
    try {
      const deleteRes = await deleteComponentApi.request(componentData.associated_table, obj, password);
      if (deleteRes) {
        if (deleteRes?.data?.code === 500) {
          callBack(deleteRes?.data?.error?.message);
        } else {
          toggle((prev) => !prev);
          callBack(undefined);
        }
      }
    } catch (error) {
      callBack("Incorrect Password!");
    }
  };
  const unarchive = async (obj, password, callBack) => {
    try {
      const unarchiveRes = await unarchiveComponentApi.request(componentData.associated_table, obj, password);
      if (unarchiveRes) {
        if (unarchiveRes?.data?.code === 500) {
          callBack(unarchiveRes?.data?.error?.message);
        } else {
          toggle((prev) => !prev);
          callBack(undefined);
        }
      }
    } catch (error) {
      callBack("Incorrect Password!");
    }
  };
  const view = (id) => {
    // setId(id);
    console.log("view");
  };
  const categoryObject = (e) => {
    const temp = Object();
    temp[e.value] = true;
    return temp;
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handlePageSize = (e) => {
    setSizeOfPages(Number(e.target.value));
  };

  return (
    <>
      <div className="d-flex justify-content-center mx-4 mb-2">
        <h2 className="text-secondary">{componentData.name}</h2>
      </div>
      <div className="d-flex justify-content-between align-items-center  m-4">
        <span style={{ display: "flex", gap: "10px" }}>
          {componentData.is_filters === 1 ? (
            <Select
              className="react-select"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: "230px",
                }),
              }}
              cacheOptions
              defaultOptions
              placeholder="Select Categories Filter"
              options={[
                { value: null, label: "Show All" },
                ...Object.entries(componentData.filters).map(([key, value]) => {
                  return { value: key, label: value.label };
                }),
              ]}
              onChange={(e) => {
                if (e.value) {
                  setIsCategory(e);
                } else {
                  setIsCategory(null);
                }
                setCurrentPage(1);
              }}
              value={isCategory}
            />
          ) : (
            ""
          )}
          <input type="search" placeholder="Search" className="px-3 rounded" value={search} onChange={handleSearchInput} />
        </span>
        <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <select
            // style={{ borderRadius: "5px" }}
            className="showing-entries"
            onChange={handlePageSize}
          >
            <option value={10} selected>
              Showing 10
            </option>
            <option value={20}>Showing 20</option>
            <option value={30}>Showing 30</option>
          </select>
          <button className="button-primary" onClick={onClickAddModal}>
            {componentData.button_label}
          </button>
        </span>
      </div>
      {noRecord ? (
        <div style={{ padding: "50px 0px" }}>
          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
              textAlign: "center",
              padding: "35px",
              fontWeight: "bolder",
              borderRadius: "10px",
            }}
          >
            Record Not Found
          </div>
        </div>
      ) : (
        <>
          <CustomizeTableview
            data={data}
            thumbnail={componentData.name === "Brand" ? { img: "logo" } : undefined}
            columnNotShow={["is_mobile", "is_laptop", "is_tab", "is_desktop", "is_deleted", "is_led", "is_accessory", "logo"]}
            dateColumn={["created_at"]}
            edit={edit}
            archive={archive}
            unarchive={unarchive}
          />
          <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
        </>
      )}

      <PopUpModal size="md" title={`Add ${componentData.name}`} show={showAddComponentModal} onHide={() => setShowAddComponentModal(false)}>
        <AddComponent close={() => setShowAddComponentModal(false)} toggle={toggle} fieldData={componentData} />
      </PopUpModal>
      <PopUpModal size="md" title={`Edit ${componentData.name}`} show={showEditComponentModal} onHide={() => setShowEditComponentModal(false)}>
        <AddComponent close={() => setShowEditComponentModal(false)} toggle={toggle} fieldData={componentData} data={editData} />
      </PopUpModal>
    </>
  );
};

export default Component;
