import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import triangle from "../../assets/images/triangle.svg";
import dangerline from "../../assets/images/dangerline.svg";
import loginPageStyle from "../../styles/Login.module.css";
import axios from "axios";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LiveModel({ model, show, close }) {
  const [isSaving, setIsSaving] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  const [passwordInput, setPasswordInput] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordError, setPasswordError] = useState(false);
  const onHide = () => {
    setIsSaving(false);
    setPasswordInput("");
    setShowPassword(false);
    setPasswordError("");
    setActionPerformed(false);
    close();
  };
  const onSubmit = async () => {
    try {
      setIsSaving(true);
      setActionPerformed(true);
      if (!passwordInput) {
        return;
      }
      const response = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/live/" + model.id, { password: passwordInput }, { withCredentials: true });
      if (response) {
        setIsSaving(false);
        onHide();
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        setPasswordError(error?.response?.data?.error?.message);
      }
      setIsSaving(false);
    }
  };

  const onBackForEdit = async () => {
    try {
      setActionPerformed(true);
      setIsSaving(true);
      const response = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/back-to-edit/" + model.id, { password: passwordInput, backToReview: true }, { withCredentials: true });
      if (response) {
        setIsSaving(false);
        onHide();
      }
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        setPasswordError(error?.response?.data?.error?.message);
      }
      setIsSaving(false);
    }
  };

  return (
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide} fullscreen="sm-down" backdrop="static">
      <Modal.Header className="py-2" closeButton>
        <Modal.Title>Live Model</Modal.Title>
      </Modal.Header>

      <>
        <Modal.Body className="py-3 px-5">
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Live this Model?</span>
          </div>
          <Form.Group className={`mb-4 ${loginPageStyle.passwordInput}  password`} md="4" controlId="validationCustomPassword">
            <Form.Control
              className="p-2"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              onChange={(e) => {
                setPasswordInput(e.target.value);
                if (isSaving) {
                  setIsSaving(false);
                }
              }}
              required
              isInvalid={actionPerformed && (!passwordInput.length || passwordError.length > 0)}
            />
            <div className={`${loginPageStyle.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
            </div>
            <Form.Control.Feedback type="invalid">* {passwordError.length > 0 ? passwordError : "Invalid Password"}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
      </>
      <Modal.Footer className="border-0">
        <Button variant="outline-secondary" onClick={onHide} disabled={isSaving}>
          Close
        </Button>
        <Button variant="outline-danger" onClick={onBackForEdit} disabled={isSaving}>
          Back for edit
        </Button>
        <Button onClick={onSubmit} disabled={isSaving}>
          Live
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
