import { useState, useEffect } from "react";
import NavOption from "./NavOption";

const MenuItem = ({
  setIsSandwichOpen,
  // subnavigation,
  item,
  isSandwichOpen,
  active,
  setActive,
  activeParent,
  setActiveParent,
  activeCaret,
  setActiveCaret,
  isSubNavExists,
  parentIdentifier,
}) => {
  const [isOpen, setIsOpen] = useState(activeCaret === item.title);

  return (
    <>
      {/* <NavOption
        subnavigation={item.subnavigation}
        setIsSandwichOpen={setIsSandwichOpen}
        isSandwichOpen={isSandwichOpen}
        title={item.title}
        icon={item.icon}
        path={item.path}
        identifier={item.path}
        isSubNav={item.subNav?.length > 0}
        active={active}
        setActive={setActive}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      /> */}
      <NavOption
        subnavigation={item.subnavigation}
        setIsSandwichOpen={setIsSandwichOpen}
        isSandwichOpen={isSandwichOpen}
        title={item.title}
        icon={item.icon}
        activeIcon={item.activeIcon ?? item.icon}
        path={item.linkTo ? item.linkTo : item.path}
        Subnav={item.subNav}
        isSubNav={isSubNavExists ? item.subNav?.length > 0 : false}
        navPath={item.linkTo ? item.linkTo : item.path}
        identifier={item.title}
        active={active}
        setActive={setActive}
        activeParent={activeParent}
        parentIdentifier={parentIdentifier}
        setActiveParent={setActiveParent}
        activeCaret={activeCaret}
        setActiveCaret={setActiveCaret}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      {activeCaret === item.title &&
        isOpen &&
        item.subNav?.map((subItem, index) => {
          // return <MenuItem isSandwichOpen={isSandwichOpen} key={index} item={subItem} active={subActive} setActive={setSubActive} />;
          return (
            <MenuItem
              subnavigation={subItem.subnavigation}
              setIsSandwichOpen={setIsSandwichOpen}
              isSandwichOpen={isSandwichOpen}
              key={index}
              item={subItem}
              active={active}
              setActive={setActive}
              activeParent={activeParent}
              parentIdentifier={item.title}
              setActiveParent={setActiveParent}
              activeCaret={activeCaret}
              setActiveCaret={setActiveCaret}
            />
          );
        })}
    </>
  );
};

export default MenuItem;
