import { Card } from "react-bootstrap";

// const SummaryCard = ({ title, value, description }) => {
//   return (
//     <Card className="shadow-lg text-center mb-4">
//       <Card.Body>
//         <Card.Title>{title}</Card.Title>
//         <Card.Text className="h2 mb-1">{value}</Card.Text>
//         <Card.Text className="text-muted">{description}</Card.Text>
//       </Card.Body>
//     </Card>
//   );
// };

const SummaryCard = ({ title, variations }) => {
  return (
    <Card className="shadow-lg mb-4 w-100" style={{ backgroundColor: "#C5EBFE" }}>
      <Card.Body>
        <Card.Title className="text-center mb-3 pb-2 border-bottom border-white">{title}</Card.Title>
        {variations.map((variation, index) => (
          <div
            key={index}
            className={`d-flex justify-content-between align-items-center mb-2  ${index === variations.length - 1 ? "" : "pb-2 border-bottom border-light"}`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <h6 className="mb-0 text-muted">{variation.label}</h6>
            <p className="mb-0 fw-bold text-dark">{variation.value}</p>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default SummaryCard;
