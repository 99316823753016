import { Link, useLocation } from "react-router-dom";

const Breadcrumb = () => {
  const location = useLocation();
  const path = location.pathname;

  const items = path.slice(1).split("/");
  let pathArr = [
    {
      label: "Dashboard",
      path: "/",
    },
  ];
  if (items[0] !== "") {
    let stringPath = "/";
    items.map((item, index, array) => {
      if (!isNaN(item)) {
        return null;
      } else {
        if (!isNaN(array[index + 1])) {
          if (item === "images") {
            stringPath = stringPath + item;
            pathArr.push({
              label: "Model No." + array[index + 1],
              path: stringPath,
            });
          } else {
            stringPath = stringPath + item + "/" + array[index + 1];
            if (item === "edit") {
              pathArr.push({
                label: item,
                path: stringPath,
              });
            } else {
              if (!isNaN(array[index - 1])) {
                pathArr.push({
                  label: array[index - 2] + " no. " + array[index + 1],
                  path: stringPath,
                });
              } else {
                pathArr.push({
                  label: array[index - 1] + " no. " + array[index + 1],
                  path: stringPath,
                });
              }
            }
          }
        } else {
          stringPath = stringPath + item;

          pathArr.push({
            label: item,
            path: stringPath,
          });
        }
        stringPath = stringPath + "/";
      }
    });
  }
  return (
    <>
      <div style={{ padding: "10px" }}>
        {pathArr.map((obj, i) => {
          if (i === 0) {
            if (pathArr.length > 1) {
              return (
                <span key={i}>
                  <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                  <Link
                    to={obj.path}
                    style={{
                      color: "#A93BFF",
                      textDecoration: "none",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              );
            } else {
              return (
                <span key={i}>
                  <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                  <span
                    style={{
                      color: "gray",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </span>
                </span>
              );
            }
          } else if (pathArr.length === i + 1) {
            return (
              <span key={i}>
                <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                <span style={{ textTransform: "capitalize" }}>
                  <Link
                    to={obj.path}
                    style={{
                      pointerEvents: "none",
                      textDecoration: "none",

                      textDecoration: "none",
                      color: "gray",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              </span>
            );
          } else {
            return (
              <span key={i}>
                <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                <span style={{ textTransform: "capitalize" }}>
                  <Link
                    to={obj.path}
                    style={{
                      color: "#A93BFF",
                      textDecoration: "none",

                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              </span>
            );
          }
        })}
      </div>
    </>
  );
};

export default Breadcrumb;
