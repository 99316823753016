import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import triangle from "../../assets/images/triangle.svg";
import dangerline from "../../assets/images/dangerline.svg";
import axios from "axios";

export default function AddForReview({ model, show, close }) {
  const [isSaving, setIsSaving] = useState(false);
  const onHide = () => {
    setIsSaving(false);
    close();
  };
  const onSubmit = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/add-for-review/" + model.id, {}, { withCredentials: true });
      if (response) {
        setIsSaving(false);
        onHide();
      }
    } catch (error) {}
  };

  return (
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide} fullscreen="sm-down" backdrop="static">
      <Modal.Header className="py-2" closeButton>
        <Modal.Title>Add For Review</Modal.Title>
      </Modal.Header>

      <>
        <Modal.Body className="py-3 px-5">
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Add this Model for review?</span>
          </div>
          {!model?.images && <div className="d-flex justify-content-center text-danger">*You need to add Images before this step.</div>}
        </Modal.Body>
      </>
      <Modal.Footer className="border-0">
        <Button variant="outline-secondary" onClick={onHide} disabled={isSaving}>
          Close
        </Button>
        <Button onClick={onSubmit} disabled={isSaving || !model?.images}>
          {isSaving ? "Adding..." : "Add For Review"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
