import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import style from "./../../styles/Sidebar.module.css";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";

const Menu = ({ setIsSandwichOpen, isSandwichOpen, navData }) => {
  const user = useSelector((store) => store.auth.user);
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const navDataMap = useMemo(
    () =>
      navData.reduce((acc, item) => {
        acc[item.path] = item.title;
        if (item?.subNav?.length > 0) {
          item.subNav.forEach((subItem) => {
            acc[subItem.path] = item.title;
          });
        }
        return acc;
      }, {}),
    [navData],
  );
  const [activeParent, setActiveParent] = useState(navDataMap[location.pathname]);
  const [activeCaret, setActiveCaret] = useState(navDataMap[location.pathname]);
  useEffect(() => {
    setActiveParent(navDataMap[location.pathname]);
    setActiveCaret(navDataMap[location.pathname]);
    setActive(location.pathname);
  }, [location.pathname]);
  return (
    <>
      {navData
        .filter((item) => (item?.users ? item?.users?.includes(user.user_type) : true))
        .map((item, index) => {
          return (
            // <MenuItem
            //   subnavigation={item.subnavigation}
            //   setIsSandwichOpen={setIsSandwichOpen}
            //   item={item}
            //   key={index}
            //   isSandwichOpen={isSandwichOpen}
            //   active={active}
            //   setActive={setActive}
            // />

            <MenuItem
              subnavigation={item.subnavigation}
              setIsSandwichOpen={setIsSandwichOpen}
              item={item}
              key={index}
              isSandwichOpen={isSandwichOpen}
              active={active}
              setActive={setActive}
              activeParent={activeParent}
              setActiveParent={setActiveParent}
              activeCaret={activeCaret}
              setActiveCaret={setActiveCaret}
              isSubNavExists={item?.subNav?.length > 0}
            />
          );
        })}
    </>
  );
};

export default Menu;
