import React from "react";
import { useState } from "react";
import Topbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar/Sidebar";
import style from "./../styles/Sidebar.module.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const DashboardLayout = () => {
  const [isSandwichOpen, setIsSandwichOpen] = useState(false);
  const toggle = () => {
    setIsSandwichOpen((prev) => !prev);
  };

  return (
    <div className={`${style.dashboardLayout}`}>
      <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} />
      <div className={`${isSandwichOpen ? style.sidebarLayoutClose : style.sidebarLayout}`} style={{ zIndex: "2", position: "relative" }}>
        <Sidebar
          setIsSandwichOpen={setIsSandwichOpen}
          isSandwichOpen={isSandwichOpen}
          // toggle={toggle}
        />
      </div>
      <div
        className={`
         ${isSandwichOpen ? style.contentClose : style.content}`}
      >
        <div className={`${style.contentDiv} ${style.scroll}  `}>
          <div className="mt-1">
            <Breadcrumb />
          </div>
          <div className={`mt-2 px-2`}>
            <Outlet />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
    </div>
  );
  // return (
  //   <div className={`d-flex flex-column ${style.dashboardLayout}`}>
  //     <Topbar toggle={toggle} isSandwichOpen={isSandwichOpen} />
  //     <div className={`fixed-top ${style.sidebarLayout}`} style={{ zIndex: "2" }}>
  //       <Sidebar setIsSandwichOpen={setIsSandwichOpen} isSandwichOpen={isSandwichOpen} toggle={toggle} />
  //     </div>
  //     <div className={`mt-5 ${style.content} ${isSandwichOpen ? style.contentClose : ""}`}>
  //       <Outlet />
  //     </div>
  //     <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
  //   </div>
  // );
};

export default DashboardLayout;
