import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ModelTableView from "../../Components/TableView/ModelTableView";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaginationBar from "../../Components/PaginationBar/PaginationBar";
import Metadata from "../../Components/Metadata/Metadata";
import ModelFilter from "../../Components/Model/ModelFilter/ModelFilter";
import ModelViewModal from "../../Components/Model/ModelViewModal/ModelViewModal";
import ExportExcelModal from "../../Components/ExportExcelModal/ExportExcelModal";
import ModelMetaExcelActions from "../../Components/Model/ModelMetaExcelActions/ModelMetaExcelActions";
import ImportExcelModal from "../../Components/ImportExcelModal/ImportExcelModal";
import ImportExcelModalReleaseDateAndLaunchPrice from "../../Components/ImportExcelModalReleaseDateAndLaunchPrice/ImportExcelModalReleaseDateAndLaunchPrice";
import Select from "react-select";
import { useSelector } from "react-redux";
import AddForReview from "../../Components/AddForReview/AddForReview";
import LiveModel from "../../Components/LiveModel/LiveModel";

const DELAY_TIME = 100;

const Mobiles = () => {
  const user = useSelector((store) => store.auth.user);

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const [dataRefresh, toggle] = useState(false);

  const [noRecord, setNoRecord] = useState(false);

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [statusFilter, setStatusFilter] = useState({
    value: null,
    label: "All",
  });

  const [addForReviewModal, setAddForReviewModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const closeAddForReviewModal = () => {
    setAddForReviewModal(false);
    setSelectedModel(null);
    toggle((prev) => !prev);
  };
  const [liveModelModal, setLiveModelModal] = useState(false);
  const closeLiveModelModal = () => {
    setLiveModelModal(false);
    setSelectedModel(null);
    toggle((prev) => !prev);
  };

  const [search, setSearch] = useState("");
  const currentSearch = useRef();
  currentSearch.current = search;

  const [searchParams] = useSearchParams();

  const [viewModal, setViewModal] = useState(false);
  const [id, setId] = useState(-1);

  const closeViewModal = () => {
    setViewModal(false);
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, dataRefresh, searchParams, noOfPages]);

  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/mobiles?page=" + page);
  };

  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      axios.get(process.env.REACT_APP_URL_API_DATA + "/model/mobiles/no-of-pages?" + new URLSearchParams({ size: sizeOfPages, search, ...(statusFilter?.value !== null ? { isDeleted: statusFilter.value } : {}) }).toString()).then((res) => {
        if (res.data.no_of_pages === 0) {
          setNoRecord(true);
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/mobiles?page=0");
          }
        } else {
          setNoRecord(false);
          setNoOfPages(res.data.no_of_pages);
          if (currentPage > res.data.no_of_pages) {
            setCurrentPage(res.data.no_of_pages);
            navigate("/mobiles?page=" + res.data.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/mobiles?page=1");
          }
        }
      });
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [sizeOfPages, dataRefresh, data, navigate, currentPage, searchParams, search, statusFilter]);

  useEffect(() => {
    const dataTimeout = setTimeout(() => {
      if (!noRecord) {
        axios.post(process.env.REACT_APP_URL_API_DATA + "/model/mobiles?page=" + currentPage + "&size=" + sizeOfPages + `${statusFilter?.value !== null ? "&isDeleted=" + statusFilter.value : ""}`, { search: search }).then((res) => {
          setData(res.data);
        });
      } else {
        setData([]);
      }
    }, DELAY_TIME);
    return () => {
      clearTimeout(dataTimeout);
      if (currentSearch.current !== search) {
        setCurrentPage(1);
        navigate("/mobiles?page=1");
      }
    };
  }, [dataRefresh, currentPage, sizeOfPages, search, navigate, noRecord, statusFilter]);

  const edit = (id) => {
    navigate("/mobiles/edit/" + id, { state: { page: currentPage } });
  };
  const duplicate = (id) => {
    navigate("/mobiles/duplicate/" + id, { state: { page: nextPage() } });
  };

  const remove = (id, password) => {
    axios
      .delete(`${process.env.REACT_APP_URL_API_DATA}/model/delete/${id}`, {
        data: { password: password },
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        toggle((prev) => !prev);
      });
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };

  const view = (id) => {
    setId(id);
    setViewModal(true);
  };
  const [showMetaModal, setShowMetaModal] = useState(false);
  const [isMetaLoading, setIsMetaLoading] = useState(false);
  const [isMetaSaving, setIsMetaSaving] = useState(false);
  const [isMetaAdded, setIsMetaAdded] = useState({ value: undefined, label: "All" });
  const [metaState, setMetaState] = useState({
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    fk_model_id: -1,
  });

  const handleMeta = async (id) => {
    try {
      setIsMetaLoading(true);
      setShowMetaModal(true);
      const metadataResponse = await axios.get(`${process.env.REACT_APP_URL_API_DATA}/model-meta/${id}`, { withCredentials: true });
      const { meta_title, meta_description, meta_keywords } = metadataResponse?.data?.success?.data;
      setMetaState({
        meta_title: meta_title,
        meta_description: meta_description || "",
        meta_keywords: meta_keywords || "",
        fk_model_id: id,
      });
      setIsMetaLoading(false);
    } catch (error) {}
  };

  const onHideMetaModal = () => {
    setShowMetaModal(false);
    setMetaState({
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      fk_model_id: -1,
    });
    setIsMetaSaving(false);
  };

  const onSubmitMetaModal = async () => {
    try {
      setIsMetaSaving(true);
      const submitResponse = await axios.patch(`${process.env.REACT_APP_URL_API_DATA}/model-meta`, metaState, { withCredentials: true });
      if (submitResponse?.data?.code) {
        onHideMetaModal();
      } else {
        setIsMetaSaving(false);
      }
    } catch (error) {}
  };

  const [showExcelExportModal, setShowExcelExportModal] = useState(false);
  const [dataForModal, setDataForModal] = useState({
    items: [],
    noOfPages: 0,
    noOfItems: 0,
  });
  const [modalCurrentPage, setModalCurrentPage] = useState(1);
  const modalChangePage = (page) => {
    setModalCurrentPage(page);
  };
  const [modalSearchInput, setModalSearchInput] = useState("");
  const getDataForModal = async () => {
    try {
      const countResponse = await axios.get(process.env.REACT_APP_URL_API_DATA + "/model/mobiles/no-of-pages?" + new URLSearchParams({ size: 50, search: modalSearchInput, onlyModel: true, ...(isMetaAdded?.value !== undefined ? { isMetaAdded: isMetaAdded?.value } : {}) }).toString());
      if (countResponse && countResponse?.data) {
        setDataForModal((prev) => ({
          ...prev,
          noOfPages: countResponse?.data?.no_of_pages || 0,
          noOfItems: countResponse?.data?.no_of_items || 0,
        }));
        if (countResponse.data.no_of_pages !== 0) {
          if (modalCurrentPage > countResponse.data.no_of_pages) {
            setModalCurrentPage(countResponse.data.no_of_pages);
          }
          // Data for modal
          const dataResponse = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/mobiles?page=" + modalCurrentPage + "&size=50", { search: modalSearchInput, onlyModel: true, ...(isMetaAdded?.value !== undefined ? { isMetaAdded: isMetaAdded?.value } : {}) });
          if (dataResponse && dataResponse?.data) {
            setDataForModal((prev) => ({
              ...prev,
              items: dataResponse?.data || [],
            }));
          }
        } else {
          setDataForModal((prev) => ({
            ...prev,
            items: [],
          }));
        }
      }
    } catch (err) {
      setDataForModal((prev) => ({
        ...prev,
        items: [],
        noOfPages: 0,
        noOfItems: 0,
      }));
    }
  };
  useEffect(() => {
    let dataTimeout;
    if (showExcelExportModal) {
      if (modalSearchInput?.length > 0) {
        dataTimeout = setTimeout(() => {
          getDataForModal();
        }, 200);
      } else {
        getDataForModal();
      }
    }
    return () => {
      clearTimeout(dataTimeout);
    };
  }, [showExcelExportModal, modalCurrentPage, modalSearchInput, isMetaAdded]);
  const onHideExportExcelModal = () => {
    setShowExcelExportModal(false);
    setModalCurrentPage(1);
    setModalSearchInput("");
    setDataForModal({
      items: [],
      noOfPages: 0,
      noOfItems: 0,
    });
    setIsMetaAdded({ value: undefined, label: "All" });
  };

  const [showImportExcelModal, setShowImportExcelModal] = useState(false);

  const onHideImportExcelModal = () => {
    setShowImportExcelModal(false);
  };

  const nextPage = () => {
    return data.length % sizeOfPages === 0 ? noOfPages + 1 : noOfPages;
  };
  // Release Date & Launch Price
  const [forLaunchPriceFilter, setForLaunchPriceFilter] = useState();
  const [showExportModalForReleaseDate, setShowExportModalForReleaseDate] = useState(false);
  const [dataForModalForReleaseDate, setDataForModalForReleaseDate] = useState({
    items: [],
    noOfPages: 0,
    noOfItems: 0,
  });
  const [modalCurrentPageForReleaseDate, setModalCurrentPageForReleaseDate] = useState(1);
  const modalChangePageForReleaseDate = (page) => {
    setModalCurrentPageForReleaseDate(page);
  };
  const [modalSearchInputForReleaseDate, setModalSearchInputForReleaseDate] = useState("");

  const getDataForModalForReleaseDate = async () => {
    const countResponse = await axios.get(process.env.REACT_APP_URL_API_DATA + "/model/mobiles/no-of-pages?" + new URLSearchParams({ size: 50, search: modalSearchInputForReleaseDate, ...(forLaunchPriceFilter?.value !== undefined ? { isLaunchPrice: forLaunchPriceFilter.value } : {}) }).toString());
    if (countResponse && countResponse?.data) {
      setDataForModalForReleaseDate((prev) => ({
        ...prev,
        noOfPages: countResponse?.data?.no_of_pages || 0,
        noOfItems: countResponse?.data?.no_of_items || 0,
      }));
      if (countResponse.data.no_of_pages !== 0) {
        if (modalCurrentPageForReleaseDate > countResponse.data.no_of_pages) {
          setModalCurrentPageForReleaseDate(countResponse.data.no_of_pages);
        }
        // Data for modal
        const dataResponse = await axios.post(process.env.REACT_APP_URL_API_DATA + "/model/mobiles?page=" + modalCurrentPageForReleaseDate + "&size=50" + (forLaunchPriceFilter?.value !== undefined ? "&isLaunchPrice=" + forLaunchPriceFilter.value : ""), { search: modalSearchInputForReleaseDate });
        if (dataResponse && dataResponse?.data) {
          setDataForModalForReleaseDate((prev) => ({
            ...prev,
            items: dataResponse?.data || [],
          }));
        }
      } else {
        setDataForModalForReleaseDate((prev) => ({
          ...prev,
          items: [],
        }));
      }
    }
  };
  useEffect(() => {
    let dataTimeout;
    if (showExportModalForReleaseDate) {
      if (modalSearchInputForReleaseDate?.length > 0) {
        dataTimeout = setTimeout(() => {
          getDataForModalForReleaseDate();
        }, 200);
      } else {
        getDataForModalForReleaseDate();
      }
    }
    return () => {
      clearTimeout(dataTimeout);
    };
  }, [showExportModalForReleaseDate, modalCurrentPageForReleaseDate, modalSearchInputForReleaseDate, forLaunchPriceFilter]);

  const onHideExportExcelModalForReleaseDate = () => {
    setShowExportModalForReleaseDate(false);
    setModalCurrentPageForReleaseDate(1);
    setModalSearchInputForReleaseDate("");
    setDataForModalForReleaseDate({
      items: [],
      noOfPages: 0,
      noOfItems: 0,
    });
    toggle((prev) => !prev);
    setForLaunchPriceFilter(undefined);
  };

  const [showImportExcelModalForReleaseDate, setShowImportExcelModalForReleaseDate] = useState(false);

  const onHideImportExcelModalForReleaseDate = () => {
    setShowImportExcelModalForReleaseDate(false);
  };

  // END
  return (
    <>
      <ImportExcelModal show={showImportExcelModal} close={onHideImportExcelModal} />
      <ExportExcelModal
        show={showExcelExportModal}
        close={onHideExportExcelModal}
        data={dataForModal}
        currentPage={modalCurrentPage}
        changePage={modalChangePage}
        search={modalSearchInput}
        setSearch={setModalSearchInput}
        filters={[
          <Select
            className="react-select"
            styles={{
              container: (provided) => ({
                ...provided,
                width: "230px",
              }),
            }}
            placeholder="Select Filter"
            options={[
              { value: undefined, label: "All" },
              { value: true, label: "Is Meta Added" },
              { value: false, label: "Is Meta Not Added" },
            ]}
            onChange={setIsMetaAdded}
            value={isMetaAdded}
          />,
        ]}
      />
      {/* For Release Date and Launch Price */}
      <ExportExcelModal
        title="Modal"
        show={showExportModalForReleaseDate}
        close={onHideExportExcelModalForReleaseDate}
        data={dataForModalForReleaseDate}
        currentPage={modalCurrentPageForReleaseDate}
        changePage={modalChangePageForReleaseDate}
        search={modalSearchInputForReleaseDate}
        setSearch={setModalSearchInputForReleaseDate}
        onlyModal={false}
        exportFormat={(data) => {
          return {
            model_id: data.model_id,
            model_title: data.model_title,
            model_name: data.model_name,
            brand_name: data.brand_name,
            release_date: data.release_date?.split("T")[0] ?? "",
            launch_price: data.launch_price ?? "",
          };
        }}
        filters={[
          <Select
            className="react-select"
            styles={{
              container: (provided) => ({
                ...provided,
                width: "230px",
              }),
            }}
            placeholder="Select Filter"
            options={[
              { value: undefined, label: "All" },
              { value: true, label: "Have Launch Price" },
              { value: false, label: "Not have Launch Price" },
            ]}
            onChange={setForLaunchPriceFilter}
            value={forLaunchPriceFilter}
          />,
        ]}
      />
      <ImportExcelModalReleaseDateAndLaunchPrice show={showImportExcelModalForReleaseDate} close={onHideImportExcelModalForReleaseDate} />

      {/* END For Release Date and Launch Price */}
      <Metadata show={showMetaModal} close={onHideMetaModal} isLoading={isMetaLoading} isSaving={isMetaSaving} state={metaState} setState={setMetaState} onSubmit={onSubmitMetaModal} />
      <ModelFilter title="Mobiles" addButton={{ title: "Add Mobile", link: "/mobiles/add", page: nextPage() }} search={{ value: search, setValue: handleSearchInput }} statusFilter={{ value: statusFilter, setValue: setStatusFilter }} />

      {noRecord ? (
        <div style={{ padding: "50px 0px" }}>
          <div
            style={{
              width: "100%",
              height: "100px",
              backgroundColor: "white",
              textAlign: "center",
              padding: "35px",
              fontWeight: "bolder",
              borderRadius: "10px",
            }}
          >
            Record Not Found
          </div>
        </div>
      ) : (
        <>
          {["data-analyst", "seo"].includes(user.user_type) && <ModelMetaExcelActions setShowExcelExportModal={setShowExcelExportModal} setShowImportExcelModal={setShowImportExcelModal} />}
          {/* For Release Date and Launch Price */}
          {["data-analyst"].includes(user.user_type) && <ModelMetaExcelActions title={"Release Date & Launch Price"} setShowExcelExportModal={setShowExportModalForReleaseDate} setShowImportExcelModal={setShowImportExcelModalForReleaseDate} />}
          {/* END For Release Date and Launch Price */}
          <ModelTableView
            data={data}
            edit={statusFilter?.value === 1 ? undefined : ["data-analyst", "data-operator"].includes(user.user_type) ? edit : undefined}
            remove={statusFilter?.value === 1 ? undefined : ["data-analyst"].includes(user.user_type) ? remove : undefined}
            view={["data-analyst", "data-operator", "seo"].includes(user.user_type) ? view : undefined}
            duplicate={statusFilter?.value === 1 ? undefined : ["data-analyst", "data-operator"].includes(user.user_type) ? duplicate : undefined}
            meta={statusFilter?.value === 1 ? undefined : ["data-analyst", "seo"].includes(user.user_type) ? handleMeta : undefined}
            uploadImages={statusFilter?.value === 1 ? undefined : ["data-analyst", "data-operator"].includes(user.user_type)}
            dynamicButton={
              ["data-analyst", "data-operator"].includes(user.user_type)
                ? {
                    label: (row) => {
                      if (row.is_deleted === -1) {
                        return "Make Live";
                      } else if (row.is_deleted === -2) {
                        return "Add for Review";
                      }
                    },
                    show: (row) => {
                      if (row.is_deleted === -1 && ["data-analyst"].includes(user.user_type)) {
                        return true;
                      } else if (row.is_deleted === -2) {
                        return true;
                      }
                    },
                    variant: (row) => {
                      if (row.is_deleted === -1) {
                        return "outline-secondary";
                      } else if (row.is_deleted === -2) {
                        return "outline-primary";
                      }
                    },
                    action: (row) => {
                      setSelectedModel(row);
                      if (row.is_deleted === -1) {
                        // Make Live
                        setLiveModelModal(true);
                      } else if (row.is_deleted === -2) {
                        // Add for Review
                        setAddForReviewModal(true);
                      }
                    },
                  }
                : undefined
            }
          />
          <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
        </>
      )}
      <ModelViewModal show={viewModal} onHide={closeViewModal} modelTitle={"Mobile"} id={id} />
      <AddForReview model={selectedModel} show={addForReviewModal} close={closeAddForReviewModal} />
      <LiveModel model={selectedModel} show={liveModelModal} close={closeLiveModelModal} />
    </>
  );
};

export default Mobiles;
