import { useEffect, useState } from "react";
import { Table, Card } from "react-bootstrap";
import SummaryCard from "./SummaryCard";
import axios from "axios";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [summaryData, setSummaryData] = useState({
    liveModels: 0,
    waitingApproval: 0,
    addedLastWeek: 0,
    addedLastMonth: 0,
    addedLastSixMonths: 0,
    userReports: [],
  });

  const user = useSelector((store) => store.auth.user);

  useEffect(() => {
    // Fetch summary data from API or backend
    const fetchData = async () => {
      const modelStats = await fetchModelReport();
      const userStats = await fetchUserReport();
      const selfStats = await fetchSelfStats();
      const data = {
        liveModels: modelStats.total_live_models,
        waitingApproval: modelStats.total_waiting_approval,
        addedLastWeek: modelStats.live_last_7_days,
        addedLastMonth: modelStats.live_last_30_days,
        addedLastSixMonths: modelStats.live_last_180_days,
        userReports: userStats.map((user) => ({
          id: user.user_id,
          name: user.user_name,
          role: user.user_type,
          totalModelsAdded: user.total_models_added,
          totalModelsLive: user.total_models_made_live,
          modelsLiveLast7days: user.models_made_live_last_7_days,
          modelsAddedLast7days: user.models_added_last_7_days,
          modelsAddedLast30days: user.models_added_last_30_days,
          modelsAddedLast180days: user.models_added_last_180_days,
        })),
        selfStats: selfStats,
      };
      setSummaryData(data);
    };
    fetchData();
  }, []);

  const fetchModelReport = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API_DATA + "/reports/models-stats", { withCredentials: true });
      if (response) {
        return response.data.data;
      }
    } catch (error) {
      // Error handling here
    }
  };
  const fetchUserReport = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API_DATA + "/reports/users-stats", { withCredentials: true });
      if (response) {
        return response.data.data;
      }
    } catch (error) {
      // Error handling here
    }
  };
  const fetchSelfStats = async () => {
    try {
      if (user.user_type === "seo") {
        return undefined;
      }
      const response = await axios.get(process.env.REACT_APP_URL_API_DATA + "/reports/self-stats", { withCredentials: true });
      if (response) {
        return response.data.data;
      }
    } catch (error) {
      // Error handling here
    }
  };

  return (
    <div className="min-vh-100 p-4 bg-light">
      <div className="text-center mb-4">
        <h1 className="display-4">Welcome to TechBazaar</h1>
        <h3 className="text-secondary">Data Modules</h3>
      </div>

      {["data-analyst", "data-operator"].includes(user.user_type) && (
        <div className="my-5">
          <h2 className="h4 mb-3">My Stats</h2>
          {user.user_type === "data-analyst" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Review</th>
                  <th>Live</th>
                  <th>Reopen</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-nowrap">Last 7 days</th>
                  <td>{summaryData?.selfStats?.review_last_7_days}</td>
                  <td>{summaryData?.selfStats?.live_last_7_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_7_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_7_days}</td>
                </tr>
                <tr>
                  <th className="text-nowrap">Last 30 days</th>
                  <td>{summaryData?.selfStats?.review_last_30_days}</td>
                  <td>{summaryData?.selfStats?.live_last_30_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_30_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_30_days}</td>
                </tr>
                <tr>
                  <th className="text-nowrap">Last 180 days</th>
                  <td>{summaryData?.selfStats?.review_last_180_days}</td>
                  <td>{summaryData?.selfStats?.live_last_180_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_180_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_180_days}</td>
                </tr>
              </tbody>
            </Table>
          )}
          {user.user_type === "data-operator" && (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Added</th>
                  <th>In-process</th>
                  <th>Live</th>
                  <th>Reopoened</th>
                  <th>Deleted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="text-nowrap">Last 7 days</th>
                  <td>{summaryData?.selfStats?.added_last_7_days}</td>
                  <td>{summaryData?.selfStats?.process_last_7_days}</td>
                  <td>{summaryData?.selfStats?.live_last_7_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_7_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_7_days}</td>
                </tr>
                <tr>
                  <th className="text-nowrap">Last 30 days</th>
                  <td>{summaryData?.selfStats?.added_last_30_days}</td>
                  <td>{summaryData?.selfStats?.process_last_30_days}</td>
                  <td>{summaryData?.selfStats?.live_last_30_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_30_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_30_days}</td>
                </tr>
                <tr>
                  <th className="text-nowrap">Last 180 days</th>
                  <td>{summaryData?.selfStats?.added_last_180_days}</td>
                  <td>{summaryData?.selfStats?.process_last_180_days}</td>
                  <td>{summaryData?.selfStats?.live_last_180_days}</td>
                  <td>{summaryData?.selfStats?.back_to_edit_last_180_days}</td>
                  <td>{summaryData?.selfStats?.delete_last_180_days}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      )}

      <div className="d-flex flex-wrap gap-4">
        <SummaryCard
          title="Model Reports"
          variations={[
            { label: "Live Models", value: summaryData.liveModels },
            { label: "Waiting Approval", value: summaryData.waitingApproval },
            { label: "Added Last 7 days", value: summaryData.addedLastWeek },
            { label: "Added Last 30 days", value: summaryData.addedLastMonth },
            { label: "Added Last 180 days", value: summaryData.addedLastSixMonths },
          ]}
        />
      </div>

      <div className="mt-5">
        <h2 className="h4 mb-3">Users Reports</h2>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>User</th>
              <th>Role</th>
              <th>Total Models Added</th>
              <th>Models Added last 7 days</th>
              <th>Models Added last 30 days</th>
              <th>Models Added last 180 days</th>
              <th>Total Models Made live</th>
              <th>Models Made live last 7 days</th>
            </tr>
          </thead>
          <tbody>
            {summaryData.userReports.map((report, index) => (
              <tr key={index}>
                <td className="text-nowrap text-capitalize">{report.name}</td>
                <td className="text-nowrap">{report.role}</td>
                <td>{report.totalModelsAdded}</td>
                <td>{report.modelsAddedLast7days}</td>
                <td>{report.modelsAddedLast30days}</td>
                <td>{report.modelsAddedLast180days}</td>
                <td>{report.totalModelsLive}</td>
                <td>{report.modelsLiveLast7days}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Dashboard;
