import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";
import Cookies from "./Cookies";
import { ALLOWED_USERTYPE } from "../utils/contants/allowedUsertypes";

const initialState = {
  user: null,
  error: false,
  message: "",
};

// Login User
export const login = createAsyncThunk("/auth/login", async (user, thunkApi) => {
  try {
    const res = await authService.login(user);
    if (ALLOWED_USERTYPE.includes(res.user.user_type)) {
      return res;
    } else {
      return thunkApi.rejectWithValue("This user is not allowed to login!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString() || error.payload.message;
    return thunkApi.rejectWithValue(message);
  }
});

export const currentUser = createAsyncThunk("/auth/get-user-by-accesstoken", async (refreshToken, thunkApi) => {
  try {
    const res = await authService.getUser(refreshToken);
    if (ALLOWED_USERTYPE.includes(res.user_type)) {
      return res;
    } else {
      return thunkApi.rejectWithValue("This user is not allowed to login!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user.user;
    },
    logout: (state, action) => {
      state.user = null;
      localStorage.removeItem("refreshToken");
      if (process.env.REACT_APP_ENV === "development") {
        Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN);
      } else {
        Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN, { domain: ".techbazaar.pk" });
      }
    },
    addUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        console.log(action);
        state.user = null;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.error = false;
        state.message = "";
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
        // state.message = "Session Expired! Please Login!";
      });
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
