import style from "./../../styles/Sidebar.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-bootstrap";

const NavOption = ({ setIsSandwichOpen, isSandwichOpen, title, icon, activeIcon, path, identifier, isSubNav, active, setActive, isOpen, setIsOpen, activeParent, parentIdentifier, setActiveParent, activeCaret, setActiveCaret, subnavigation, navPath }) => {
  // return (
  //   <div className={`${style.Sidebartooltip} d-flex align-items-center`}>
  //     <NavLink
  //       to={path}
  //       className={`${style.sideLink} my-1 d-block w-100`}
  //       onClick={() => {
  //         if (window.innerWidth < 920) {
  //           //  console.log("asdf")
  //           setIsSandwichOpen((prev) => !prev);
  //         }
  //         setActive(identifier);
  //         if (isOpen && identifier === active) {
  //           // console.log(window.innerWidth)
  //           setIsOpen(false);
  //         } else {
  //           setIsOpen(true);
  //         }
  //       }}
  //       style={() =>
  //         active === identifier
  //           ? {
  //               backgroundColor: "rgba(241, 244, 250, 1)",
  //               color: "rgba(89, 50, 234, 1)",
  //               textDecoration: "none",
  //               marginRight: "3px",
  //             }
  //           : { color: "#545e6f", textDecoration: "none", marginRight: "3px" }
  //       }
  //     >
  //       <div className={`d-flex  ${subnavigation && "ms-4 "} ${"gap-2 justify-content-between"} align-items-center`}>
  //         <div className="d-flex align-items-center gap-2">
  //           {icon}
  //           {title}
  //           {/* {!isSandwichOpen && title} */}
  //         </div>
  //       </div>
  //       {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>}
  //     </NavLink>
  //     {isSubNav && (
  //       <FontAwesomeIcon
  //         onClick={() => {
  //           setActive(identifier);
  //           if (isOpen) {
  //             setIsOpen(false);
  //           } else {
  //             setIsOpen(true);
  //           }
  //         }}
  //         style={{ paddingRight: "8px" }}
  //         icon={isOpen ? faChevronUp : faChevronDown}
  //       />
  //     )}
  //   </div>
  // );

  const location = useLocation();
  return (
    <div
      style={
        location.pathname === navPath
          ? {
              background: " #f8f6ff ",
              boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
              color: "rgba(89, 50, 234, 1) !important",
              textDecoration: "none",
            }
          : !subnavigation && activeParent === identifier
          ? {
              background: " #f8f6ff ",
              boxShadow: " 0 0 15px rgb(0 0 0 / 0%)",
              textDecoration: "none",
            }
          : { color: "#545e6f", color: "#39414A", textDecoration: "none" }
        // : { opacity: "0.6", animationFillMode: "none" }
      }
      id={`tooltip-anchor-${title.replaceAll(" ", "-")}`}
      className={` mt-1 ${subnavigation ? (!isSandwichOpen ? style.sublink : style.sublinkClose) : style.sideLink} ${subnavigation ? style.subanimate : ""} d-flex  justify-content-between align-items-center`}
    >
      <NavLink
        to={path}
        className={`${style.subSideLink}  my-1 d-block mt-2 `}
        onClick={() => {
          if (window.innerWidth < 820) {
            setIsSandwichOpen((prev) => !prev);
          }

          setIsOpen(true);
          setActive(navPath);
          if (subnavigation) {
            setActiveCaret(parentIdentifier);
            setActiveParent(parentIdentifier);
          } else {
            setActiveParent(identifier);
            setActiveCaret(identifier);
          }
        }}
      >
        <div className={`d-flex ${subnavigation && " "} ${isSandwichOpen ? "justify-content-center" : "gap-2 justify-content-between"} align-items-center`}>
          <div className={`d-flex  align-items-center gap-2  ${!isSandwichOpen ? "w-100" : ""} `}>
            {active === navPath || activeParent === identifier ? activeIcon : icon}
            <span className={`${active === navPath || activeParent === identifier ? style.activeColor : ""} d-flex justify-content-between w-100  `}>
              {" "}
              <span> {!isSandwichOpen && title}</span>
            </span>
          </div>
        </div>
        {/* {isSandwichOpen && <span className={style.tooltipClose}>{title}</span>} */}
      </NavLink>
      <div
        className={`p-2 ${isSandwichOpen && "d-none"}`}
        onClick={() => {
          setActiveCaret(identifier);
          if (isOpen) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
          if (activeCaret !== identifier) {
            setIsOpen(true);
          }
        }}
      >
        {!isSandwichOpen && isSubNav && <FontAwesomeIcon icon={activeCaret === identifier && isOpen ? faChevronUp : faChevronDown} />}
      </div>
    </div>
  );
};

export default NavOption;
