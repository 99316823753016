import React from "react";
import axios from "axios";

import { faLaptop, faMobile, faDesktop, faComputer, faHeadset, faDatabase, faTableList, faChevronDown, faChevronUp, faTablet, faReceipt, faHome } from "@fortawesome/free-solid-svg-icons";
// import { faTablet } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideNavData = [
  {
    title: "Home",
    path: "/",
    icon: <FontAwesomeIcon icon={faHome} />,
  },
  {
    title: "Laptops",
    path: "/laptops",
    icon: <FontAwesomeIcon icon={faLaptop} />,
  },
  {
    title: "Mobiles",
    path: "/mobiles",
    icon: <FontAwesomeIcon icon={faMobile} />,
  },
  {
    title: "Tablets",
    path: "/tablets",
    icon: <FontAwesomeIcon icon={faTablet} />,
  },
  {
    title: "TVs / Monitors",
    path: "/tv-monitors",
    icon: <FontAwesomeIcon icon={faDesktop} />,
  },
  {
    title: "Desktop Computers",
    path: "/desktop-computers",
    icon: <FontAwesomeIcon icon={faComputer} />,
  },
  {
    title: "Accessories",
    path: "/accessories",
    icon: <FontAwesomeIcon icon={faHeadset} />,
  },
  {
    title: "Components",
    path: "/components",
    icon: <FontAwesomeIcon icon={faDatabase} />,
    iconClose: <FontAwesomeIcon icon={faChevronDown} />,
    iconOpen: <FontAwesomeIcon icon={faChevronUp} />,
    subNav: [],
    users: ["data-analyst", "data-operator"],
  },
  {
    title: "Requests",
    path: "/requests",
    icon: <FontAwesomeIcon icon={faReceipt} />,
    users: ["data-analyst"],
  },
];

export default SideNavData;
